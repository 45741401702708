module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Qranio - Making Learning Fun","short_name":"Qranio","description":"Desenvolver pessoas, melhorar a performance e ver resultado. Um Software feito sob medida para resolver todas as demandas de Treinamento Corporativo e Gamificação das mais diversas empresas e organizações.","lang":"pt-BR","display":"standalone","icon":"./src/assets/images/logo-favicon.png","start_url":"/","background_color":"#FDC436","theme_color":"#fff","localize":[{"start_url":"/pt-br/","lang":"pt-BR","name":"Qranio - Making Learning Fun","short_name":"Qranio","description":"Desenvolver pessoas, melhorar a performance e ver resultado. Um Software feito sob medida para resolver todas as demandas de Treinamento Corporativo e Gamificação das mais diversas empresas e organizações."},{"start_url":"/en-us/","lang":"en","name":"Qranio - Making Learning Fun","short_name":"Qranio","description":"Develop people, improve performance and see results. A Software made to measure to solve all the demands of Corporate Training and Gamification of the most diverse companies and organizations."},{"start_url":"/es-es/","lang":"es","name":"Qranio - Making Learning Fun","short_name":"Qranio","description":"Desarrollar personas, mejorar el desempeño y ver resultados. Un Software hecho a la medida para resolver todas las demandas de Formación Corporativa y Gamificación de las más diversas empresas y organizaciones."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"09642bed1b0f360f5a3288ac6773513f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/github/workspace","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/github/workspace/src/intl","languages":["pt-br","en-us","es-es"],"defaultLanguage":"pt-br","redirect":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
